.swaper {

    margin-top: 0;
    margin-bottom: 70px;
}

.swaperinner {
    text-align: left;
    width: 70%;
    margin-left: 20%;
}


.swap1 {
    color: white;
    font-size: 30px;
    font-weight: 900;
    margin-left: 20px;
}

.swap2 {
    color: white;
    margin-left: 20px;
    font-size: 15px;
    margin-bottom: 20px;
}


.swapinput{
    position: relative;

}

#bnblogo{
    position: absolute;
    width: 5%;
    top: 5%;
    left: 78%;
}

#gla{
    position: absolute;
    width: 5%;
    top: 55%;
    left: 78%;
}

#bnblogo1{
    position: absolute;
    width: 5%;
    top: 55%;
    left: 78%;
}

#gla1{
    position: absolute;
    width: 5%;
    top: 5%;
    left: 78.3%;
}

.input1 {
    background-color: #181D2E;
    border: none;
    height: 50px;
    display: block;
    margin: auto;
    width: 80%;
    margin-bottom: 30px;
    color: white;
    font-size: 20px;
    padding-left: 20px;
    border-radius: 20px;
    margin-bottom: 70px;
    
}


.tokenname {
    color: white;
    font-size: 16px;
    font-weight: 900;
    margin-left: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.balance {
    position: relative;
}

.tokenvalue {
    position: absolute;
    color: white;
    font-size: 16px;
    font-weight: 900;
    left: 70%;
    margin-bottom: 0;
    padding-bottom: 0;

}

.swapbtnbox {
    text-align: center;
}

.swapbtn {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 200px;
    height: 50px;
    background-color: #0F87E7;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    color: white;
}

.approve{
    position: absolute;
    cursor: pointer;
    top:25%;
    left: 40%;
    width: 90px;
    height: 30px;
    background-color: #0F87E7;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    color: white;
}

#tip1{
    font-size: 16px;
    position: absolute;
    color: rgb(111, 17, 91);
    top: 37%;
    left: 35%;
}