.launchpad p{
    margin-left: 40px;
}
.launchpad form{
    margin-left: 40px;
}

.launchpad{
    color: white;
    font-weight: 400;
    position: relative;
}

.tip{
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 25px;
    color: #7E79DE;
}

.tokencreate{
    position: absolute;
    top: 45px;
    left: 84%;
    background-color:#0983E6;
    border: none;
    width: 100px;
    height: 40px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}

.tokenaddress{
    margin-left: 40px;
    font-size: 20px;
}


.tokeninput{
    margin-left: 40px;
    width: 90%;
    background-color: #181D2E;
    border: none;
    height: 45px;
    display: block;
    width: 90%;
    color: white;
    font-size: 20px;
    border-radius: 20px;
    padding-left: 20px;
    
}

.fee{
    margin-left: 40px;
    font-size: 10px;
    color: #CF4473;
}
.blanktip{
    margin-left: 40px;
    font-size: 10px;
    color: #7E79DE;
    margin-bottom: 10px;
}

.currency{
    font-size: 20px;
}
.feeop{
    margin-top: 10px;
}

.recommended{
    color: #CF4473;
    font-size: 10px;
}

#lastp{
    margin-left:0;
   text-align: center;
    margin-top: 20px;
    color:#B19F4E;
    border: 1px solid #B19F4E;
    width: 100%;
}

.next{
    text-align: center;

}

.next button{
    background-color: #0b216c;
    border: none;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    color:rgb(249, 244, 244);
    font-size: 20px;
    
}