.headernft{
    text-align: center;
    margin-top: 20px;
}

.headernft span{
    font-size: 50px;
    font-weight: 900;
    color: #807DE6;
}
#span2{
    color: #FEFEFE;
}

.tandm{
    position: relative;
}

#t{
    padding-left: 10px;
    padding-top: 8px;
    text-align: left;
    width: 140px;
    height: 50px;
    background-color: #807DE6;
    display: inline-block;
    border-radius: 8px;
    margin-right: 10px;
}

#m{
    display: inline-block;
}

#tspan{
    position: absolute;
    left: 3.3%;
    color: white;
    font-size: 20px;
    font-weight: 900;
}

#m{
    padding-left: 10px;
    padding-top: 8px;
    text-align: left;
    width: 140px;
    height: 50px;
    background-color: #1A1B20;
    display: inline-block;
    border-radius: 8px;
    margin-right: 10px;
}
#mspan{
    position: absolute;
    left: 13%;
    color: #9E9FA0;
    font-size: 20px;
    font-weight: 900;
}
.add{
    position: relative;
}

.change{
    position: absolute;
    top: 8px;
    left: 0;
}

.change span{
    font-size: 10px;
    position: relative;
}

#cspan1{
    top: -10px;
    left: 2px;
}
#cspan2{
    left: -60px;
}

.iinput{
    position: absolute;
    margin-left: 200px;
}

.iinput input{
    width: 450%;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: #707583;
    color: white;
    font-size: 20px;
    padding-left: 30px;
}

.lasttime{
    position:absolute;
    left: 80%;
}

.tbox{
    display: inline-block;
    height: 40px;
    padding-top: 4px;
}
#t1{
    text-align: center;
    width: 100px;
    border-radius: 10px 0 0 10px;
    background-color: #7272DE;
}
#t2{
    text-align: center;
    width: 60px;
    border-radius: 0;
    border-right: 1px;
    background-color: #707583;
}

#t3{
    text-align: center;
    width: 60px;
    border-radius: 0;
    background-color: #707583;
}
#t4{
    text-align: center;
    width: 60px;
    border-radius: 0 10px 10px 0;
    background-color: #707583;
}

div ~ #t2{
    border-right: 1px solid #181D2E;
}
div ~ #t3{
    border-right: 1px solid #181D2E;
}


#searchicon{
    position: absolute;
    top:5%;
    left: 420%;
}