.box {
    position: relative;
    overflow: hidden;
    width: 90%;
    height: 1438px;
    margin: auto;
    border-radius: 30px;

}


.kline {

    position: absolute;
    width: 100%;
    margin-top: -244px;
    margin-left: 0;

}


@media screen and (min-width: 1600px) {

    .box {
        position: relative;
        overflow: hidden;
        width: 80%;
        height: 1438px;
        margin: auto;
        border-radius: 30px;

    }

    .kline {
        position: absolute;
        width: 120%;
        margin-top: -244px;
        margin-left: -23.3%;

    }

}